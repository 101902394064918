import request from '../request'

//休假类型列表
export const vacation_category_index_request = p => {
    return request({
        method:'GET',
        url:'vacation_category/index',
        params: p
    })
}

//添加休假类型
export const vacation_category_add_request = d => {
    return request({
        method:'POST',
        url:'vacation_category/add',
        data: d
    })
}

//删除休假类型
export const vacation_category_del_request = id => {
    return request({
        method:'DELETE',
        url:'vacation_category/del',
        data: {
            id: id
        }
    })
}

//修改休假类型
export const vacation_category_edit_request = d => {
    return request({
        method:'PUT',
        url:'vacation_category/edit',
        data: {
            id: d.id,
            name: d.name,
            type: d.type
        }
    })
}

//获取休假类型详情
export const vacation_category_details_request = id => {
    return request({
        method:'GET',
        url:'vacation_category/details',
        params: {
            id
        }
    })
}