<template>
  <div>
    <el-dialog
      :title="dialogFormTitle"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      width="30%"
      :center="true"
      top="20vh"
      :close-on-click-modal="false"
      :before-close="() => closeDialog()"
      v-loading.fullscreen.lock="login_loading"
    >
      <div>
        <el-form
          :model="VacationCategory"
          :rules="rules"
          ref="VacationCategoryEditForm"
          label-width="100px"
          size="small"
        >
        <!-- 添加按钮的弹框1 -->
          <div v-if="show_type">
            <el-form-item label="休假名称" prop="name">
              <el-input v-model="VacationCategory.name" clearable></el-input>
            </el-form-item>
            <el-form-item label="休假类别" prop="bank">
                <el-radio-group v-model="VacationCategory.type" size="medium" :fill="type_color">
                    <el-radio-button :label="0">普通假</el-radio-button>
                    <el-radio-button :label="1">特殊假</el-radio-button>
                </el-radio-group>
            </el-form-item>
          </div>
          <!-- 添加成功后详情弹框0 -->
          <div v-else>
            <el-descriptions
              class="margin-top"
              :column="1"
              :labelStyle="{ width: '120px', 'text-align': 'center' }"
              :contentStyle="{ 'text-align': 'center' }"
              border
            >
              <el-descriptions-item>
                <template slot="label">
                  <span>类型名称</span>
                </template>
                <span>{{VacationCategory.name}}</span>
              </el-descriptions-item>
              <el-descriptions-item>
                  <template slot="label">
                      <span>休假类别</span>
                  </template>
                  <el-tag v-if="VacationCategory.type" type="warning">特殊假</el-tag>
                  <el-tag v-else type="primary">普通假</el-tag>
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </el-form>
      </div>
      <!-- 底部按钮展示 -->
      <div slot="footer" class="dialog-footer">
        <!-- 详情底部按钮0 -->
        <div v-if="show_type === 0">
          <el-button
            v-if="$_has(edit_auth)"
            type="primary"
            plain
            @click="$emit('show_edit')"
            >修改</el-button
          >
          <el-button
            v-if="$_has(del_auth)"
            type="danger"
            plain
            @click="VacationCategoryDel('VacationCategoryEditForm')"
            >删除</el-button
          >
          <el-button plain @click="closeDialog()"
            >关闭</el-button
          >
        </div>
        <!-- 添加底部按钮1 -->
        <div v-else-if="show_type === 1">
          <el-button
            type="success"
            plain
            @click="submitInsert('VacationCategoryEditForm')"
            >确定</el-button
          >
          <el-button type="warning" plain @click="resetForm()">重置</el-button>
          <el-button plain @click="closeDialog()"
            >关闭</el-button
          >
        </div>
        <!-- 修改底部按钮2 -->
        <div v-else>
          <el-button
            type="success"
            plain
            @click="submitEdit('VacationCategoryEditForm')"
            >确定修改</el-button
          >
          <el-button plain @click="closeDialog()"
            >取消操作</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  vacation_category_add_request,
  vacation_category_del_request,
  vacation_category_edit_request,
} from "@/network/hrm/VacationCategory.js";

export default {
  name: "VacationCategoryEditComponent",
  data() {
    return {
      login_loading: false,
      VacationCategory: {
        name: '',
        type: '',
      },
      rules: {
        name: [
          {
            required: true,
            message: "名称必填",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    dialogFormTitle() {
      if (this.show_type === 0) {
        return "休假类型详情";
      } else if (this.show_type === 1) {
        return "添加休假类型";
      } else {
        return "修改休假类型";
      }
    },
    type_color() {
      if(this.VacationCategory.type === 0) {
          return '#409eff'
      }
      if(this.VacationCategory.type === 1) {
          return '#e6a23c;'
      }
      
      return '#FFFFFF'
    }
  },
  methods: {
    closeDialog() {
      this.resetForm();
      this.$emit("exitDialog");
    },
    submitInsert(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return;
        }
        this.VacationCategoryInsert(formName);
      });
    },
    VacationCategoryInsert(formName) {
      this.login_loading = true;
      vacation_category_add_request(this.VacationCategory)
        .then((s) => {
          this.login_loading = false;
          if (s.status === 0) {
            this.$message.success("添加成功!");
            this.$emit("exitDialog");
            this.$emit("search");
            this.$emit("details_row", s.result.id * 1);
          } else {
            this.$message.error(s.msg);
          }
        })
        .catch((err) => {
          this.login_loading = false;
          this.$message.error(err);
        });
    },
    resetForm() {
      this.VacationCategory = {
        name: '',
        type: '',
      };
    },
    submitEdit(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return;
        }
        this.VacationCategoryEdit(formName);
      });
    },
    VacationCategoryEdit(formName) {
      this.login_loading = true;
      this.VacationCategory.id = this.id;
      vacation_category_edit_request(this.VacationCategory)
        .then((s) => {
          this.login_loading = false;
          if (s.status === 0) {
            this.$message.success("修改成功!");
            this.$emit("exitDialog");
            this.$emit("search");
            this.$emit("details_row", this.VacationCategory.id);
          } else {
            this.$message.error(s.msg);
          }
        })
        .catch((err) => {
          this.login_loading = false;
          this.$message.error(err);
        });
    },
    VacationCategoryDel(formName) {
      this.$confirm(
        "是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）",
        "温馨提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          vacation_category_del_request(this.id)
            .then((s) => {
              if (s.status === 0) {
                this.$message.success("删除成功!");
                this.$emit("search");
              } else {
                this.$message.error(s.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err);
            })
            .finally(() => this.closeDialog());
        })
        .catch((err) => {});
    },
  },
  filters: {},
  props: {
    dialogFormVisible: Boolean,
    id: {
      type: Number,
      default() {
        return 0;
      },
    },

    vacation_category_details_data: {
      type: Object,
      default() {
        return {};
      },
    },
    show_type: {
      type: Number,
      default() {
        return 0;
      },
    },
    edit_auth: String,
    del_auth: String,
  },
  created() {},
  destoryed() {},
  mounted() {},
  updated() {},
  components: {},
  watch: {
    vacation_category_details_data(newVal) {
      this.VacationCategory = newVal;
    },
  },
};
</script>

<style lang="less"></style>
